import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import ModalStore, { ModalType } from 'src/stores/ModalStore';
import { GPSActivationResult } from 'src/types/commons/gps';
import { Invoice, InvoiceLine, InvoiceWithSparePart } from 'src/types/commons/invoices';
import { OrderNotification } from 'src/types/commons/notifications';
import { OrderHeader } from 'src/types/commons/orders';
import {
    Battery,
    Bike,
    BikeInService,
    CompatibleBike,
    MarketingMaterialsResponse,
    SparePart,
    SparePartParentBike,
} from 'src/types/commons/products';
import { Reason, ReturnRequest, ReturnRequestType, SparePartsReturn } from 'src/types/commons/returns';
import { Translations } from 'src/types/commons/translation';
import { UserPreferences } from 'src/types/commons/users';
import CONFIG from 'src/utils/config';
import { salesForceLangCode } from 'src/utils/constants';
import { convertToTranslations } from 'src/utils/convertToTranslation';
import { objectToQueryString } from 'src/utils/objectToQueryString';
import Security from '../Security';
import { CreateOrder } from './models';

const { API, GRIDLY_API_KEY, GRIDLY_VIEW_ID } = CONFIG;

type AuthHeader = {
    token: string;
};

class RestService {
    // @ts-ignore
    private static backendPrefix: string = 'dp';
    setBackendPrefix(prefix: string) {
        RestService.backendPrefix = prefix;
    }

    server: AxiosInstance;
    user: string = '';

    constructor() {
        this.server = axios.create({
            baseURL: API,
            timeout: 40000,
        });

        if (Security.validUserCookie()) {
            const user = Security.getUser();
            if (user && user.token) {
                this.setHeaders({ token: user.token });
            }
        }

        this.server.interceptors.response.use(
            (response: AxiosResponse) => {
                const formattedResponse = { meta: response?.data?.meta, ...response };
                if (response.data && response.data.data) {
                    formattedResponse.data = response.data.data;
                }

                return formattedResponse;
            },
            (error: AxiosError) => {
                const originalRequest = error.config;
                const { status } = error.request;

                const redirectToLoginPage = () => {
                    Security.clean();
                    window.location.replace('/unauthorized');
                };

                if (status === 401) {
                    redirectToLoginPage();
                } else if (status === 503) {
                    // status 503 unavailable server, when Centraal lose connection to sf, retry if we still have user id
                    if (this.user && originalRequest) {
                        return axios.request(originalRequest);
                    } else {
                        redirectToLoginPage();
                    }
                } else if (
                    status !== 404 &&
                    !error.request.responseURL.match(/storage/) &&
                    !error.request.responseURL.match(/refresh-token/) &&
                    !error.request.responseURL.match(/res\/\.cloudinary/) &&
                    !error.request.responseURL.match(/notifications/) &&
                    !error.request.responseURL.match(/gps/)
                ) {
                    this.showErrorModal();
                }

                return Promise.reject(error);
            },
        );
    }

    showErrorModal() {
        ModalStore.set({
            title: 'notifications.error_title',
            message: 'notifications.error_message',
            type: ModalType.ERROR,
            buttonText: 'notifications.error_ok',
        });
    }

    setUser(user: string) {
        this.user = user;
    }

    // https://bitbucket.org/qwic/commons/src/master/src/types/Auth.ts
    setHeaders({ token }: AuthHeader) {
        this.server.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    getTranslations(): AxiosPromise {
        return this.server.get('/translations');
    }

    getGridlytranslations(lang: string, offset: number = 0, limit: number = 1000): Promise<Translations> {
        return axios
            .get(
                `https://api.gridly.com/v1/views/${GRIDLY_VIEW_ID}/records?columnIds=${lang
                    .split('-')
                    .join('')}&page=%7B%22offset%22%3A${offset}%2C+%22limit%22%3A${limit}%7D`,
                {
                    headers: { Authorization: `ApiKey ${GRIDLY_API_KEY}` },
                },
            )
            .then((response: any) => {
                return convertToTranslations(response.data);
            });
    }

    // User
    getStorageData(): AxiosPromise {
        return this.server.get('/dp/users/storage');
    }

    updateStorageData(type: string, snapshot: string): AxiosPromise {
        return this.server.post('/dp/users/storage', { type, snapshot });
    }

    getUserPreferences(): AxiosPromise {
        return this.server.get('/dp/users/preferences');
    }

    changeUserPreferences(data: UserPreferences): AxiosPromise {
        return this.server.patch('/dp/users/preferences', data);
    }

    createCaseContact(data: FormData): AxiosPromise {
        return this.server.post('/dp/contact', data);
    }

    getRecentNews(lang: string, search: string = '', limit: number = 20, offset: number = 0): AxiosPromise {
        return this.server.get(`/dp/news/news?lang=${lang}&q=${search}&limit=${limit}&offset=${offset}`);
    }

    getMarketingBanners(lang: string, search: string): AxiosPromise {
        return this.server.get(`/dp/news/banners?lang=${lang}&q=${search}`);
    }

    // Bike assets
    getBikeAssets(limit: number, offset: number): AxiosPromise {
        return this.server.get(`/microPlatform/bikes?limit=${limit}&offset=${offset}`);
    }

    getOneBikeAssetById(SFId: string): AxiosPromise {
        return this.server.get(`/microPlatform/bikes/${SFId}`);
    }

    // Orders
    getOrderNotifications(dateFrom: dayjs.Dayjs, dateTo: dayjs.Dayjs = dayjs()): AxiosPromise<OrderNotification[]> {
        const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
        return this.server.get(
            `/dp/orders/notifications?dateFrom=${dateFrom.format(dateFormat)}&dateTo=${dateTo.format(dateFormat)}`,
        );
    }

    getOneOrderByOrderId(orderId: string | undefined): AxiosPromise<{ data: OrderHeader[] }> {
        return this.server.get(`/microPlatform/orders/${orderId}`);
    }

    getAllOrders(limit: number = 1000, offset: number = 0): AxiosPromise<{ data: OrderHeader[] }> {
        return this.server.get(`/microPlatform/orders?limit=${limit}&offset=${offset}`);
    }

    updateOrder(orderId: string, data: any): AxiosPromise {
        return this.server.patch(`/dp/orders/${orderId}`, data);
    }

    updateOrderLine(orderId: string, data: { references: { [key: string]: string }[] }): AxiosPromise {
        return this.server.patch(`/dp/orders/${orderId}/line`, data);
    }

    placeOrder(createOrder: CreateOrder): AxiosPromise<{ data: any }> {
        return this.server.post('/dp/orders', [createOrder]);
    }

    // Products
    getBikes(queryParams?: { serie: string; country: string }): AxiosPromise<Bike[]> {
        const params = objectToQueryString(queryParams);
        return this.server.get(`/microPlatform/products/bikes?${params}`);
    }

    getBikeSizes(): AxiosPromise<{ data: string[] }> {
        return this.server.get(`/microPlatform/products/bikes/sizes`);
    }

    getBikesInService(): AxiosPromise<{ data: BikeInService[] }> {
        return this.server.get(`/microPlatform/products/bikes/inService`);
    }

    getBikesAutocomplete(frameNumber: string): any {
        return this.server.get(`/microPlatform/products/bikes/autocomplete?frameNumber=${encodeURIComponent(frameNumber)}`);
    }

    getBatteries(queryParams: { country: string }): AxiosPromise<Battery[]> {
        const params = objectToQueryString(queryParams);
        return this.server.get(`/microPlatform/products/batteries?${params}`);
    }

    getAllSparePartsBySearchTerm(searchString: string): AxiosPromise<{ data: SparePart[] }> {
        return this.server.get(`/microPlatform/products/spareParts?q=${searchString}`);
    }

    getSparePartsByPartId(partId: string): AxiosPromise<{ data: { spareParts: SparePart[] } }> {
        return this.server.get(`/microPlatform/products/spareParts/${partId}`);
    }

    getSparePartsByProductCode(
        productCode: string,
    ): AxiosPromise<{ data: { products: SparePart[]; parentBike: SparePartParentBike } }> {
        return this.server.get(`/microPlatform/products/spareParts?bikeProductCode=${productCode}`);
    }

    getSparePartsByFrameNumber(
        frameNumber: string,
    ): AxiosPromise<{ data: { products: SparePart[]; parentBike: SparePartParentBike } }> {
        return this.server.get(`/microPlatform/products/spareParts?frame=${frameNumber}`);
    }

    getCompatibleBikesByPartId(partId: string): AxiosPromise<{ data: CompatibleBike[] }> {
        return this.server.get(`/microPlatform/products/spareParts/${partId}/compatibleBikes`);
    }

    getBikesByPartNumber(
        partNumber: string,
    ): AxiosPromise<{ data: { bom: string; code: string; description: string }[] }> {
        return this.server.get(`/microPlatform/products/bikes/partId/${partNumber}`);
    }

    getMarketingMaterials(limit: number = 1000, offset: number = 0): AxiosPromise<MarketingMaterialsResponse> {
        return this.server.get(`/microPlatform/products/marketingMaterial?limit=${limit}&offset=${offset}`);
    }

    getMarketingMaterialById(partId: string): AxiosPromise<MarketingMaterialsResponse> {
        return this.server.get(`/microPlatform/products/marketingMaterial/${partId}`);
    }

    // Invoices
    getInvoicesByAccountId(dateStart?: string, dateEnd?: string): AxiosPromise<Invoice[]> {
        let params = '';

        if (dateStart) {
            params += `?startDate=${encodeURIComponent(dateStart)}`;
        }

        if (dateEnd) {
            params += `&endDate=${encodeURIComponent(dateEnd)}`;
        }

        return this.server.get(`/microPlatform/invoices${params}`);
    }

    getInvoiceLinesByInvoiceId(id: string): AxiosPromise<{ data: InvoiceLine[] }> {
        return this.server.get(`/microPlatform/invoices/${id}/lines`);
    }

    getInvoicePdfById(id: string, lang: string): AxiosPromise {
        return this.server.get(`/dp/invoices/${id}/pdf?lang=${lang}`, {
            responseType: 'blob',
        });
    }

    getInvoicesBySparePart(sparePartCode: string): AxiosPromise<{ data: InvoiceWithSparePart[] }> {
        return this.server.get(`/microPlatform/invoices/sparePart/${sparePartCode}`);
    }

    getInvoiceById(invoiceId: string): AxiosPromise<{ data: Invoice }> {
        return this.server.get(`/microPlatform/invoices/${invoiceId}`);
    }

    createPayment(invoices: string[]): AxiosPromise<{ url: string }> {
        return this.server.post('/dp/invoices/pay', { invoices });
    }

    // Return requests
    getReturnRequests(limit: number = 1000, offset: number = 0): AxiosPromise<{ data: ReturnRequestType[] }> {
        return this.server.get(`/dp/returnRequests?limit=${limit}&offset=${offset}`);
    }

    getReturnRequestById(returnId: string): AxiosPromise<{ data: ReturnRequestType }> {
        return this.server.get(`/dp/returnRequests/${returnId}`);
    }

    getReturnRequestPdf(returnId: string, lang: string): AxiosPromise {
        return this.server.get(`/dp/returnRequests/${returnId}/pdf?lang=${lang}`, {
            responseType: 'blob',
        });
    }

    createReturnRequest(data: ReturnRequest): AxiosPromise {
        return this.server.post('/dp/returnRequests', data);
    }

    getReturnReasons(): AxiosPromise<{ data: Reason[] }> {
        return this.server.get('/microPlatform/legacy/returnRequestReasons');
    }

    getSparePartsReturnStatus(productCode: string): AxiosPromise<{ data: SparePartsReturn }> {
        return this.server.get(`/microPlatform/legacy/sparePartsReturnStatus/${productCode}`);
    }

    // GPS
    activateGPS(frameNumber: string, IMEI: string): AxiosPromise<GPSActivationResult> {
        return this.server.post('/dp/gps/activate', { frameNumber, IMEI });
    }

    // KnowledgeBase
    getKnowledgeBaseUrl(lang: string, returnUrl: string = ''): AxiosPromise<string> {
        const mappedLang = lang ? salesForceLangCode[lang] : '';
        return this.server.get(`/dp/knowledgeBase?lang=${mappedLang}${returnUrl ? `&returnUrl=${returnUrl}` : ''}`);
    }

    // Upload
    uploadFile(data: FormData) {
        return this.server.post('/dp/upload', data);
    }
}

// @TODO: make it into 1 instance, check how many instances are created;
export default new RestService();
